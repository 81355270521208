import Config from "../../config";

export const BASE_URL = Config.env().BASE_URL;
export const TENANT = Config.env().TENANT;

const API_CONSTANTS = {
  DIRECTION: {
    trackLink: "/public/tracking/latest-location",
    tripDetails: "/public/tracking/trip-detail"
  },
  STRIPE: {
    refresh: "/common/payment/refresh-onboarding-link/:accountId"
  }
};

export default API_CONSTANTS;
