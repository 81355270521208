import {
  BsCheckCircle,
  BsChevronLeft,
  BsChevronDown,
  BsChevronRight,
  BsFillCaretDownFill,
  BsChevronUp,
  BsArrowUpShort,
  BsArrowDownShort,
  BsCaretDown,
  BsCaretDownFill,
  BsCaretUp,
  BsCaretUpFill,
  BsCarFront,
  BsCarFrontFill,
  BsX,
} from "react-icons/bs";

import {
  AiOutlineCalendar,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineMenuFold,
  AiTwotoneStar,
} from "react-icons/ai";
import { FiUserPlus } from "react-icons/fi";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import { BiRadioCircleMarked } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import {
  MdOutlineAddLocationAlt,
  MdVerified,
  MdPayments,
} from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { CgSupport } from "react-icons/cg";

const APP_ICONS = {
  //NAVIGATION ICONS
  SUPPORT: CgSupport,
  LOGOUT: FiLogOut,
  STAR: AiTwotoneStar,

  //Booking Details

  PAYMENT: MdPayments,
  //actions

  //Navigation Icons
  LeftChevron: BsChevronLeft,
  RightChevron: BsChevronRight,
  UpChevron: BsChevronUp,
  DownChevron: BsChevronDown,
  UpArrow: BsArrowUpShort,
  DownArrow: BsArrowDownShort,
  CaretDown: BsCaretDown,
  CaretDownFill: BsCaretDownFill,
  CaretUp: BsCaretUp,
  CaretUpFill: BsCaretUpFill,
  CLOSE: BsX,
  HEAVY_ALERT: FcHighPriority,
  CHECK_GREEN: FcCheckmark,

  DropDownIcon: BsFillCaretDownFill,

  Drawer: AiOutlineMenuFold,
  //Misc

  BOOKING_REQUEST: MdOutlineAddLocationAlt,
  CHECK_CIRCLED: BsCheckCircle,
  PERSON_PLUS: FiUserPlus,
  LOCK: FaLock,
  EYE_OFF: AiOutlineEyeInvisible,
  EYE: AiOutlineEye,
  CALENDAR: AiOutlineCalendar,
  PICKUP: BsCarFront,
  DROP_OFF: MdVerified,
  STOP: BiRadioCircleMarked,
  markerIcon: BsCarFrontFill,
};

export default APP_ICONS;
