import { colorKeys } from "../constant/appColors";
import PickUpLocationIcon from "../../assets/PickUpLocationIcon.svg";
import DropOffLocationIcon from "../../assets/DropOffLocationIcon.svg";
import StopsLocationSvg from "../../assets/StopsLocationSvg.svg";

export const getDefaultCoordinates = () => {
    return {
        lat: 24.878665443156397,
        lng: 67.08664774715301,
    }
}

export const prepareStops = (index, arr) => {
    // const icon = index === 0 ? APP_ICONS.PICKUP : index === arr.length - 1 ? APP_ICONS.DROP_OFF : APP_ICONS.STOP;
    const icon = index === 0 ? PickUpLocationIcon : index === arr.length - 1 ? DropOffLocationIcon : StopsLocationSvg;
    const color = index === 0 ? colorKeys.primaryButtonFill : index === arr.length - 1 ? colorKeys.primaryButtonFill : colorKeys.gray;
    const label = index === 0 ? "Pickup" : index === arr.length - 1 ? "Dropoff" : "Stop";
    return { icon, color, label }
}