import { extendTheme } from "@chakra-ui/react";

const fonts = {
  heading: `'Poppins', sans-serif`,
  body: `'Poppins', sans-serif`,
};

const config = {
  initialColorMode: "light",
  useSystemColorMode: localStorage.getItem("systemColorMode") || false,
};

export const theme = extendTheme({
  fonts,
  config,
});