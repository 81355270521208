import { Avatar, Box, Button, Divider, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, HStack, Heading, Icon, IconButton, Image, SkeletonCircle, Text, useColorMode, useDisclosure } from '@chakra-ui/react';
import React from 'react'
import { colorKeys, getColor } from '../../config/constant/appColors';
import APP_ICONS from '../../config/constant/icons';
import { prepareStops } from '../../config/helpers/locaionHelper';

const MobileRideInfoDrawer = ({ tripDetails, isLoading, lastLocation }) => {

    const { colorMode } = useColorMode();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <HStack
                pos="absolute"
                w="100vw"
                top={"0px"}
                display={{ base: "block", md: "none" }}
            >
                <HStack
                    align="stretch"
                    as={Button}
                    onClick={onOpen}
                    w="full"
                    h={"80px"}
                    alignItems={"center"}
                    bg={`${getColor(colorKeys.primaryButtonFill, colorMode)} !important`}
                    justifyContent={"space-between"}
                    borderRadius={"none"}
                >
                    <HStack ml="12px">
                        {isLoading
                            ? <SkeletonCircle size="50" />
                            : <Avatar
                                size="md"
                                name={tripDetails?.driverName}
                                src={tripDetails?.profilePictureUrl}
                            />}

                        <Box>
                            <Heading
                                fontSize="18px"
                                fontWeight={{ base: "500", md: "600" }}
                                color={getColor(colorKeys.white, colorMode)}
                            >
                                {tripDetails?.driverName || "N/A"}
                            </Heading>

                            <Text
                                fontSize="14px"
                                color={getColor(colorKeys.white, colorMode)}
                                fontWeight={{ base: "300", md: "600" }}
                                textAlign={"left"}
                            >
                                {tripDetails?.color}-{tripDetails?.model}
                            </Text>
                            {(typeof tripDetails?.rating !== "undefined" || tripDetails?.rating !== null) && (
                                <Flex align="center">
                                    <Icon
                                        as={APP_ICONS.STAR}
                                        color={"#FFC107"}
                                    ></Icon>
                                    <Text
                                        pl={1}
                                        color={getColor(colorKeys.white, colorMode)}
                                        fontWeight={{ base: "300", md: "600" }}
                                    >
                                        {tripDetails?.rating?.toFixed(1)}
                                    </Text>
                                </Flex>
                            )}
                        </Box>
                    </HStack>

                    <Box>
                        <Text
                            mr="12px"
                            fontSize="12px"
                            color={getColor(colorKeys.white, colorMode)}
                            fontWeight={{ base: "300", md: "600" }}
                        >
                            Est: {lastLocation?.estRemainingDuration} / {lastLocation?.estRemainingDistance}
                        </Text>
                        <Text
                            mr="12px"
                            color={getColor(colorKeys.white, colorMode)}
                            fontWeight={{ base: "300", md: "600" }}
                        >
                            {tripDetails?.licensePlate}
                        </Text>
                    </Box>
                </HStack>
            </HStack>

            <Drawer
                placement="top"
                onClose={onClose}
                isOpen={isOpen}
                h={"full"}
                w={{ base: "full", md: onClose }}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerBody mt={3}>
                        <Flex justify="space-between" mb={2}>
                            <Box>
                                <Heading
                                    fontSize="18px"
                                    color={getColor(colorKeys.primaryButtonFill, colorMode)}
                                >
                                    Estimated Time & Duration
                                </Heading>
                                <Text>{lastLocation?.estRemainingDuration && lastLocation?.estRemainingDuration ? `${lastLocation?.estRemainingDuration} / ${lastLocation?.estRemainingDistance}` : "N/A"}</Text>
                                <Text>{lastLocation?.estRemainingDuration} / {lastLocation?.estRemainingDistance}</Text>
                            </Box>

                            <IconButton variant={"unstyled"} icon={<Icon as={APP_ICONS.CLOSE} boxSize={"7"} />} onClick={onClose} />
                        </Flex>
                        <Divider orientation="horizontal" />
                        <Flex justify={"space-between"}>
                            <HStack align="stretch" alignItems={"center"} my={3}>
                                <Avatar
                                    size="md"
                                    name={tripDetails?.driverName}
                                    src={tripDetails?.profilePictureUrl}
                                />

                                <Box>
                                    <Heading fontSize="18px">{tripDetails?.driverName}</Heading>
                                    {tripDetails?.rating && <Flex align="center">
                                        <Icon
                                            as={APP_ICONS.STAR}
                                            color={"#FFC107"}
                                        ></Icon>
                                        <Text pl={1}>{tripDetails?.rating?.toFixed(1)}</Text>
                                    </Flex>}
                                </Box>
                            </HStack>
                            <Box textAlign={"center"}>
                                <Text fontSize="14px" pt={3.5}>
                                    {tripDetails?.color}-{tripDetails?.model} / Type
                                </Text>
                                <Text fontSize="14px">{tripDetails?.licensePlate}</Text>
                            </Box>
                        </Flex>
                        <Divider orientation="horizontal" />

                        <Box my={3}>
                            {tripDetails?.rideStops?.map((item, index, arr) => {
                                const { icon, color, label } = prepareStops(index, arr)
                                return (
                                    <Box key={index} p="5px 10px" minW="250px">
                                        <Flex>
                                            {/* <Icon
                                                as={icon}
                                                color={getColor(color, colorMode)}
                                                mr={2}
                                            /> */}
                                            <Image w={22} src={icon} mt={-55} />
                                            <Box>
                                                <Heading
                                                    color={getColor(color, colorMode)}
                                                    fontWeight={"500"}
                                                    fontSize={"13px"}
                                                >
                                                    {label}
                                                </Heading>
                                                <Text
                                                    {...(index !== arr.length - 1 && {
                                                        borderLeft: `1px dashed ${getColor(
                                                            colorKeys.gray,
                                                            colorMode
                                                        )}`,
                                                        pos: "relative",
                                                        left: "-14px",
                                                        pl: "17px",
                                                    })}
                                                    mt={2}
                                                    fontSize="13px"
                                                    pb={0}
                                                >
                                                    {item?.address}
                                                </Text>
                                            </Box>
                                        </Flex>
                                    </Box>
                                );
                            })}
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default MobileRideInfoDrawer