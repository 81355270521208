import { Avatar, Box, Card, CardBody, Divider, Flex, HStack, Heading, Icon, chakra, IconButton, SkeletonCircle, Text, useColorMode, useDisclosure, Image } from '@chakra-ui/react';
import React from 'react'
import { colorKeys, getColor } from '../../config/constant/appColors';
import APP_ICONS from '../../config/constant/icons';
import { prepareStops } from '../../config/helpers/locaionHelper';

const RideInfoBox = ({ tripDetails, isLoading, lastLocation }) => {
    const { isOpen, onToggle } = useDisclosure()
    const { colorMode } = useColorMode();

    return (
        <Card
            display={{ base: "none", md: "block" }}
            w="full"
            maxW={{ base: "275px", md: "420px" }}
            minH={isOpen ? { base: "450px", md: "340px" } : "75px"}
            pos="absolute"
            zIndex="1"
            top="10"
            left="12"
            bg={getColor(colorKeys.white, colorMode)}
            overflow={isOpen ? "unset" : "hidden"}
            height={isOpen ? "unset" : "148px"}
        >
            <CardBody p={5} maxH={"calc(100vh - 150px)"} overflow={"auto"}>
                <Flex justify="space-between" mb={2}>
                    <Box>
                        <Heading
                            fontSize="18px"
                            color={getColor(colorKeys.primaryButtonFill, colorMode)}
                        >
                            Booking# {tripDetails?.rideId}
                        </Heading>
                        <Text><chakra.span>Estimated:</chakra.span> {lastLocation?.estRemainingDuration && lastLocation?.estRemainingDuration ? `${lastLocation?.estRemainingDuration} / ${lastLocation?.estRemainingDistance}` : "N/A"}</Text>
                    </Box>
                    <IconButton variant={"unstyled"} icon={<Icon as={isOpen ? APP_ICONS.UpChevron : APP_ICONS.DownChevron} boxSize={"6"} />} onClick={onToggle} />
                </Flex>
                <Divider orientation="horizontal" />
                <Flex justify={"space-between"}>
                    <HStack align="stretch" my={3}>
                        {isLoading
                            ? <SkeletonCircle size="50" />
                            : <Avatar
                                size="md"
                                name={tripDetails?.driverName}
                                src={tripDetails?.profilePictureUrl}
                            />
                        }

                        <Flex flexDir={"column"} justify={"center"}>
                            <Heading fontSize="18px">{tripDetails?.driverName || "N/A"}</Heading>
                            {(typeof tripDetails?.rating !== "undefined" || tripDetails?.rating !== null) && <Flex align="center">
                                <Icon
                                    as={APP_ICONS.STAR}
                                    color={getColor(colorKeys.warning, colorMode)}
                                ></Icon>
                                <Text pl={1}>{tripDetails?.rating}</Text>
                            </Flex>}
                        </Flex>
                    </HStack>
                    <Box textAlign={"center"}>
                        <Text fontSize="14px" pt={3.5}>
                            {tripDetails?.color}-{tripDetails?.model} / Type
                        </Text>
                        <Text fontSize="14px">{tripDetails?.licensePlate}</Text>
                    </Box>
                </Flex>

                <Divider orientation="horizontal" />
                <Box my={3}>
                    {tripDetails?.rideStops?.map((item, index, arr) => {
                        const { icon, color, label } = prepareStops(index, arr)
                        return (
                            <Box key={index} p="5px 10px" minW="250px">
                                <Flex>
                                    <Image w={22} src={icon} mt={"-40px"} />
                                    <Box>
                                        <Heading
                                            color={getColor(color, colorMode)}
                                            fontWeight={"500"}
                                            fontSize={"13px"}
                                        >
                                            {label}
                                        </Heading>
                                        <Text
                                            {...(index !== arr.length - 1 && {
                                                borderLeft: `1px dashed ${getColor(
                                                    colorKeys.gray,
                                                    colorMode
                                                )}`,
                                                pos: "relative",
                                                left: "-14px",
                                                pl: "17px",
                                            })}
                                            mt={2}
                                            fontSize="13px"
                                            pb={0}
                                        >
                                            {item?.address}
                                        </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        );
                    })}
                </Box>
            </CardBody>
        </Card>
    )
}

export default RideInfoBox