import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

const Home = () => {
    window.location.href = "https://www.ydriveapp.com/";
  return (
    <Flex justify={"center"} alignItems={"center"} h="100vh" w="100vw">
      <Spinner size={"xl"} colorScheme="blue" />
    </Flex>
  );
};

export default Home;
