const errorHandler = (messageObject) => {
    let message;
    message = messageObject?.message
    if (messageObject?.validationErrors) {
        message = Object.values(messageObject?.validationErrors?.at(0))?.at(0) || message
    }
    message = message?.length > 130 ? "Unknown Error!" : message
    return message || "Unknown Error!"
}

export default errorHandler