import { useState, useEffect } from "react";

export const apiResponse = () => {
  return {
    data: "any",
    success: "boolean",
    message: "string",
    devMessage: "string",
    apiCode: "number",
    statusCode: "number",
  };
};

export function useNetwork() {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("offline", updateNetwork);

    window.addEventListener("online", updateNetwork);

    return () => {
      window.removeEventListener("offline", updateNetwork);

      window.removeEventListener("online", updateNetwork);
    };
  });

  return isOnline;
}
export const isUnauthorized = (error) => {
  const allowedEndPoints = [];
  if (allowedEndPoints.includes(error?.config?.url)) return false;
  return error.response?.status === 401;
};
