import { useMutation } from "@tanstack/react-query";
import { Get } from "../api";
import API_CONSTANTS from "../constant/api";

export const useRefreshLink = () => {
    return useMutation({
        mutationFn: async ({ accountId, tenant }) => {
            const data = await Get({
                path: API_CONSTANTS.STRIPE.refresh.replace(":accountId", accountId),
                toastError: false,
                tenant
            });
            return data;
        },
    });
};