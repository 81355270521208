import axios from "axios";
// import { BASE_URL,  } from "../constants/api"

import { CONTENT_TYPE } from "../constant";

import { createStandaloneToast } from '@chakra-ui/toast'
import errorHandler from "../helpers/errorHandler";
import { isUnauthorized } from "../helpers/apiHelper";
import { BASE_URL,TENANT } from "../constant/api";

const { toast } = createStandaloneToast()

async function Post({ path, token, body, toastError, toastMessage, contentType = CONTENT_TYPE.JSON }) {
  try {
    let url = BASE_URL + path;
    const headers = { "Content-Type": contentType, tenant: TENANT }
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const response = await axios.post(url, body, { headers });

    if (toastMessage) {
      let message = response.data.message?.message || "Success!"
      toast({
        title: 'Congrats!',
        description: message,
        status: 'success',
        id: message,
        isClosable: true,
        position: "top",
        variant:"subtle"
      })


    }
    return response.data;
  } catch (error) {
    if (isUnauthorized(error)) {
      localStorage.clear();
      window.location.reload();
      return;
    }
    if (toastError) {
      const message = errorHandler(error?.response?.data?.message)
      toast({
        id: message,
        title: 'Error!',
        description: message,
        status: 'error',
        isClosable: true,
        position: "top",
        variant:"subtle"
      })
    }
    console.error("Error in Post.js: ", error)
  }
}

export { Post };