import {
  Box, useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import MapBoxView from "../../components/Maps/MapBoxView";
import { queryStringToObject } from "../../config/helpers/queryHelper";
import mapIcon from "../../assets/MyLocation.svg";
import {
  useLatestLocation,
  useTripDetails,
} from "../../config/query/directionQuery";
import RideInfoBox from "./RideInfoBox";
import MobileRideInfoDrawer from "./MobileRideInfoDrawer";
import RideErrorPopup from "./RideErrorPopup";
import { RIDE_STATUSES } from "../../config/constant/enum";

let interval;

const Tracking = () => {
  const queryString = queryStringToObject();
  const rideErrorDisclosure = useDisclosure()

  const lastLocationRef = useRef()
  const lastStatusRef = useRef()
  const locationRef = useRef([])

  const [locationBox, setLocationBox] = useState(null);
  const [tripDetails, setTripDetails] = useState(null);
  const [rideError, setRideError] = useState(null)
  const [lastRideStatus, setLastRideStatus] = useState(null)
  const [isRideCompleted, setIsRideCompleted] = useState(false)

  const getLatestLocation = useLatestLocation();
  const getTripDetails = useTripDetails();
  const getCoordinatesLatestLocation = () => {
    if (getLatestLocation.isLoading || isRideCompleted) return
    if (lastRideStatus === RIDE_STATUSES.Cancelled || lastRideStatus === RIDE_STATUSES.Completed) return
    getLatestLocation
      .mutateAsync({
        token: queryString.token,
        data: queryString.data,
        startingId: lastLocationRef.current,
      })
      .then((res) => {
        const rideStatus = res?.locations?.rideStatus?.id
        if (rideStatus === RIDE_STATUSES.Cancelled || rideStatus === RIDE_STATUSES.Completed) {
          setLastRideStatus(rideStatus)
          if (!rideErrorDisclosure.isOpen) rideErrorDisclosure.onOpen()
          if (!rideError) setRideError("Ride Completed")
          setIsRideCompleted(true)
        }

        if (Array.isArray(res?.locations?.coordinates) && res?.locations?.coordinates?.length > 0) {
          lastLocationRef.current = res?.locations?.coordinates[res?.locations?.coordinates.length - 1].id
          locationRef.current = [...locationRef.current, ...res?.locations?.coordinates]
          lastStatusRef.current = res?.locations?.rideStatus

          if (!locationRef.current?.length) {
            setLocationBox(res?.locations?.coordinates)
          }
          else {
            setLocationBox([...locationRef.current]);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        if (!rideError) setRideError(error?.message || error || "Something went wrong")
        if (!rideErrorDisclosure.isOpen) rideErrorDisclosure.onOpen()
      });
  };

  useEffect(() => {
    if (lastRideStatus === RIDE_STATUSES.Cancelled || lastRideStatus === RIDE_STATUSES.Completed) {
      clearInterval(interval)
    }
  }, [lastRideStatus])

  useEffect(() => {
    if (rideError) return;
    getCoordinatesLatestLocation();
    interval = setInterval(() => {
      if (!rideError) {
        getCoordinatesLatestLocation();
      }
    }, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTripDetails
      .mutateAsync({
        token: queryString.token,
        data: queryString.data,
      })
      .then((res) => {
        if (!res) {
          console.error("Ride not found")
          if (!rideError) setRideError("Ride not found")
          if (!rideErrorDisclosure.isOpen) rideErrorDisclosure.onOpen()
          return;
        }
        lastStatusRef.current = res?.rideStatus
        const rideStatus = res?.rideStatus
        if (rideStatus === RIDE_STATUSES.Cancelled || rideStatus === RIDE_STATUSES.Completed) {
          setLastRideStatus(rideStatus)
          if (!rideErrorDisclosure.isOpen) rideErrorDisclosure.onOpen()
          setIsRideCompleted(true)
        }
        setTripDetails(res);
      })
      .catch((error) => {
        console.error(error);
        if (!rideError) setRideError(error?.message || error || "Something went wrong")
        if (!rideErrorDisclosure.isOpen) rideErrorDisclosure.onOpen()
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const center = locationBox?.at(locationBox?.length - 1)?.coordinate || tripDetails?.rideStops?.at(0)?.location

  if (!queryString.token || !queryString.data) {
    console.error("Invalid Url, token or data is missing")
    if (!rideError) setRideError("Invalid Url, token or data is missing")
    if (!rideErrorDisclosure.isOpen) rideErrorDisclosure.onOpen()
  }

  return (
    <Box>
      <RideInfoBox
        isLoading={getTripDetails.isLoading}
        tripDetails={tripDetails}
        lastLocation={locationBox?.at(locationBox.length - 1)}
      />

      <Box>
        {/* {locationBox?.length > 0 ? <MapBoxView
          containerProps={{ h: "100vh" }}
          polylineCoords={locationBox?.map(item => item.coordinate)}
          markers={tripDetails?.rideStops || []}
          center={locationBox?.at(locationBox?.length - 1)?.coordinate}
          icon={mapIcon}
        /> : <Flex flexDir={"column"} h="100vh" justify="center" align="center">
          <Spinner size="xl" />
          <Text mt={5}>Loading...</Text>
        </Flex>} */}
        <MapBoxView
          containerProps={{ h: "100vh" }}
          polylineCoords={locationBox?.map(item => item.coordinate) || []}
          markers={tripDetails?.rideStops || []}
          center={center}
          icon={mapIcon}
        />
      </Box>

      <MobileRideInfoDrawer
        tripDetails={tripDetails}
        isLoading={getTripDetails.isLoading}
        lastLocation={locationBox?.at(locationBox.length - 1)}
      />

      <RideErrorPopup
        error={rideError}
        disclosure={rideErrorDisclosure}
        rideStatus={getLatestLocation.data?.locations?.rideStatus?.id}
        success={getLatestLocation.data?.locations?.rideStatus?.id === RIDE_STATUSES.Cancelled || getLatestLocation.data?.locations?.rideStatus?.id === RIDE_STATUSES.Completed ? true : getTripDetails.isError}
      />
    </Box>
  );
};

export default Tracking;
