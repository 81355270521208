export const RIDE_STATUS = {
    0: "Cancelled",
    1: "Dispatching",
    2: "Accepted",
    3: "Driver On The Way",
    4: "Arrived and Waiting",
    5: "P.O.B",
    6: "Dropped Off",
    7: "Completed",
    8: "No Shows",
    9: "Incidents",
    10: "Parking Lot"
};

export const RIDE_STATUSES = {
    Cancelled: 0,
    WaitingForAccept: 1,
    Accepted: 2,
    DriverOnTheWay: 3,
    Arrived: 4,
    InTransit: 5,
    Reached: 6,
    Completed: 7,
    NoShows: 8,
    Incidents: 9,
    ParkingLot: 10
};