import { Text, Heading, Image, Box, Flex, HStack, Button } from "@chakra-ui/react";
import React from "react";
import { useRouteError } from "react-router-dom";
import ErrorImage from "../../../assets/error.png"

const ErrorPage = () => {

  const error = useRouteError();
  console.error("Unhandled Error Occurred! ", error);

  return (
    <Flex id="error-page" h="100vh" justify="center" align="center">
      <Box>
        <Image src={ErrorImage} />
      </Box>
      <Box>
        <Heading>Oops!</Heading>
        <Text>Sorry, an unexpected error has occurred.</Text>
        <Text>{error?.statusText || error?.message}</Text>
        <HStack spacing={5} mt={5}>
          <Button onClick={() => window.location.reload()}>
            Reload Page
          </Button>
        </HStack>
      </Box>
    </Flex>
  );
};

export default ErrorPage;
