import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Tracking from "../../views/Tracking";
import TrackingError from "../../views/Tracking/TrackingError";
import StripeError from "../../views/Stripe/StripeError";
import Stripe from "../../views/Stripe";
import StripeSuccess from "../../views/Stripe/Success";
import ErrorPage from "./Error";
import PrivacyPolicy from "../../views/PrivacyPolicy";
import Home from "../../views/Home";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/tracking",
        element: <Outlet />,
        errorElement: <TrackingError />,
        children: [
            {
                index: true,
                element: <Tracking />,
                errorElement: <TrackingError />,
            }
        ]
    },
    {
        path: "/stripe",
        element: <Stripe />,
        errorElement: <StripeError />,
    },
    {
        path: "/stripe/success",
        element: <StripeSuccess />,
        errorElement: <StripeError />,
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
        errorElement: <StripeError />,
    }
]);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
