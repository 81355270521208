import { Box, Center, HStack, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import IMAGES from "../../config/constant/images";
import { queryStringToObject } from "../../config/helpers/queryHelper";
import { useRefreshLink } from "../../config/query/stripeQuery";

const Stripe = () => {
  const refreshLinkQuery = useRefreshLink()
  const query = queryStringToObject()
  const isValidRoute = Boolean(query.account && query?.tenant)
  const errorMessage = !query.account ? "Invalid stripe account" : !query?.tenant ? "Invalid URL" : null
  const [error, setError] = useState(errorMessage)

  useEffect(() => {
    if (isValidRoute) {
      refreshLinkQuery
        .mutateAsync({ accountId: query.account, tenant: query?.tenant })
        .then((res) => window.location.href = res.data)
        .catch(() => setError("Unable to refresh link, Please try again!"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Center h="100vh">
      <Box mx="auto" mt="10">
        <Heading textAlign={"center"}>{!error ? "Redirecting in 3 2 1 ..." : "Error"}</Heading>
        <HStack spacing={1} justify={"center"}>
          <Box>
            <Center>
              <Box>
                <Image src={IMAGES.STRIP} width="186.71px" height="186.7px" mt="10" />
              </Box>
            </Center>

            <Text mt="10">{!error ? "Waiting for redirection" : error}</Text>
          </Box>
        </HStack>
      </Box>
    </Center>
  )
}

export default Stripe



