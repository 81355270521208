import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import {
  QueryClientProvider,
} from "@tanstack/react-query";
import AppRouter from "./config/routes";
import { theme } from "./config/helpers/themeHelper";
import { queryClient } from "./config/query";
import "./config/helpers/sentryHelper"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <AppRouter />
    </ChakraProvider>
  </QueryClientProvider>
);

reportWebVitals();
