import { Flex, Heading, Icon, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import React from 'react'
import APP_ICONS from '../../config/constant/icons'
import { RIDE_STATUS, RIDE_STATUSES } from '../../config/constant/enum'

const getRideErrorInfo = (rideStatus, error) => {
    switch (rideStatus) {
        case RIDE_STATUSES.Cancelled:
            return {
                icon: APP_ICONS.CLOSE,
                message: "Your ride has been cancelled!"
            }
        case RIDE_STATUSES.Completed:
            return {
                icon: APP_ICONS.CHECK_GREEN,
                message: "Your ride has been completed!"
            }
        default:
            return {
                icon: APP_ICONS.HEAVY_ALERT,
                message: JSON.stringify(error)
            }
    }
}

const RideErrorPopup = ({ error, disclosure, success = true, rideStatus }) => {
    const rideErrorInfo = getRideErrorInfo(rideStatus, error)
    return (
        <Modal closeOnOverlayClick={false} isOpen={disclosure?.isOpen} onClose={disclosure?.onClose} size={{ base: "full", md: "md" }}>
            <ModalOverlay />
            <ModalContent maxW="40rem">
                <ModalBody>
                    <Flex flexDir={"column"} justify={"center"} align="center" h="500px" maxH={"100vh"} w="full" >
                        {success && <Icon as={rideErrorInfo.icon} boxSize={"100"} />}
                        {!success && <Icon as={APP_ICONS.HEAVY_ALERT} boxSize={"120"} />}
                        <Heading fontSize={"28px"} mt="2">{success ? RIDE_STATUS?.[rideStatus] : "Error!"}</Heading>
                        <Text mt="2">{success ? rideErrorInfo.message : error}</Text>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default RideErrorPopup