import axios from "axios";
import { createStandaloneToast } from "@chakra-ui/toast";
import { CONTENT_TYPE } from "../constant";
import { isUnauthorized } from "../helpers/apiHelper";
import { BASE_URL, TENANT } from "../constant/api";

const { toast } = createStandaloneToast();

async function Get({
  path,
  token,
  toastMessage,
  toastError,
  tenant = TENANT,
  contentType = CONTENT_TYPE.JSON,
}) {
  try {
    let url = BASE_URL + path;
    const headers = { "Content-Type": contentType, tenant };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await axios.get(url, { headers });

    if (toastMessage) {
      let message = response.data.message?.message || "Success!";
      toast({
        title: "Congrats!",
        description: message,
        status: "success",
        duration: 9000,
        id: message,
        isClosable: true,
        position: "top",
      });
    }
    return response.data;
  } catch (error) {
    if (isUnauthorized(error)) {
      localStorage.clear();
      window.location.reload();
      return;
    }

    if (toastError) {
      let message = error?.response?.data.message?.message || "Unknown Error!";
      message = message.length > 130 ? "Unknown Error!" : message;
      toast({
        title: "Error!",
        description: message,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
    console.error("Error in Get.js: ", error);
  }
}

export { Get };
