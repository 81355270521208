import { Box, Center, HStack, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";
import IMAGES from "../../config/constant/images";

const StripeSuccess = () => {
    return (
        <Center>
            <Box mt="10">
                <Heading>Onboarding Completed!</Heading>
                <HStack spacing={1} justify={"center"}>
                    <Box>
                        <Center>
                            <Box>
                                <Image src={IMAGES.STRIP_COMPLETE} width="163.05px" height="163.05px" mt="10" />
                            </Box>
                        </Center>

                        <Text mt="10">You may now proceed to the application.</Text>
                    </Box>
                </HStack>
            </Box>
        </Center>
    )
}

export default StripeSuccess



