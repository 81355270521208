import React, { useRef, useState, useEffect } from 'react';
import {
    GoogleMap,
    useJsApiLoader,
    Polyline,
    Marker,
    MarkerF,
} from "@react-google-maps/api";
import Config from "../../config";
import { getBoundsPrepared, getIconsForMarkers, getMapOptions, getPolylineOptions, mapContainerStyle, updateZoomOnSingleView } from './mapConfig';

const MapBoxView = ({ polylineCoords, center = { lat: 52.52047739093263, lng: 13.36653284549709 }, icon, markers = [] }) => {
    const mapInsRef = useRef(null)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Config.env().GOOGLE_MAP_API_KEY
    })

    const [map, setMap] = React.useState(null)
    const [mapZoom, setMapZoom] = useState(8);

    const polylineRef = useRef(null);

    useEffect(() => {
        if (map) {
            if (markers && markers.length > 0) {
                const bounds = getBoundsPrepared(markers);
                map.fitBounds(bounds);
                setTimeout(() => {
                    updateZoomOnSingleView(map, mapZoom, setMapZoom)
                }, 1000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, markers]);



    const onLoad = React.useCallback(function callback(map) {
        //an example of getting and using the map instance
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={mapZoom || 8}
            defaultMaxZoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={getMapOptions()}
            ref={mapRef => mapInsRef.current = mapRef}
        >
            {polylineCoords.length && (
                <Polyline
                    ref={polylineRef}
                    path={polylineCoords}
                    draggable={false}
                    onUnmount={onUnmount}
                    options={getPolylineOptions()}
                />
            )}

            <Marker position={center} options={{ icon }} />
            {markers && markers.map((marker, index) => {
                return (
                    <MarkerF
                        key={index}
                        position={{ lat: marker?.location?.lat, lng: marker?.location?.lng }}
                        icon={{
                            url: getIconsForMarkers(index, markers.length - 1)
                        }}
                    />
                )
            })}
        </GoogleMap>
    ) : <></>
}

export default React.memo(MapBoxView)