var Config = {
    ENVIRONMENT: "PRODUCTION", //current selected environment
    ENVIRONMENTS: {
        DEVELOPMENT: {
            BASE_URL: "https://stg-ydrive-as.azurewebsites.net/api",
            TENANT: "ydrive",
            PERSIST_SECRET_KEY: "secret.dev",
            GOOGLE_MAP_API_KEY: "AIzaSyDLtrI9p7sQmgFjzQAhH48ko-de_Kc6mQM",
            APP_NAME: "YDrive Track Link"
        },
        STAGING: {
            BASE_URL: "https://stg-ydrive-as.azurewebsites.net/api",
            TENANT: "ydrive",
            PERSIST_SECRET_KEY: "secret.staging",
            GOOGLE_MAP_API_KEY: "AIzaSyDLtrI9p7sQmgFjzQAhH48ko-de_Kc6mQM",
            APP_NAME: "YDrive Track Link"
        },
        PRODUCTION: {
            BASE_URL: "https://api.ridemanagementsoftware.com/api",
            TENANT: "root",
            PERSIST_SECRET_KEY: "83jd9mjoaiskdp9kDASJDSAKDOFAKSdlaskf032kda",
            GOOGLE_MAP_API_KEY: "AIzaSyBZlU2NPuHvbtBARx1R4oQx46pMG417qZw",
            APP_NAME: "YDrive Track Link"
        }
    }
};
Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};
export default Config;