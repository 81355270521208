import { useMutation } from "@tanstack/react-query";

import { Post } from "../api";
import API_CONSTANTS from "../constant/api";

export const useLatestLocation = () => {
  return useMutation({
    mutationKey: API_CONSTANTS.DIRECTION.trackLink,
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.DIRECTION.trackLink,
        body,
        toastError: false,
      });
      return data;
    },
  });
};

export const useTripDetails = () => {
  return useMutation({
    mutationFn: async (body) => {
      const data = await Post({
        path: API_CONSTANTS.DIRECTION.tripDetails,
        body,
        toastError: false,
      })
      return data;
    },
  });
};
