import {
    Box,
    Heading,
    Container,
    Text,
    Stack,
    chakra,
} from '@chakra-ui/react'

export default function CallToActionWithAnnotation() {
    return (
        <>
            <Container maxW={'3xl'}>
                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{ base: 8, md: 14 }}
                    pt={{ base: 20, md: 36 }}>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'10%'}>
                        Privacy Policy
                    </Heading>
                    <Text >
                        Last updated: June 27, 2023 <br />
                        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
                        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Free Privacy Policy Generator.
                    </Text>
                </Stack>

                <Box mb={10}>
                    <Heading as="h1" fontSize="xl" mb={4}>
                        Interpretation and Definitions
                    </Heading>

                    {/* Definitions */}
                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Interpretation
                    </Heading>
                    <Text>
                        The words of which the initial letter is capitalized have meanings
                        defined under the following conditions. The following definitions shall
                        have the same meaning regardless of whether they appear in singular or
                        in plural.
                    </Text>

                    {/* Definitions */}
                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Definitions
                    </Heading>

                    {/* Account */}
                    <Text>
                        <strong>Account</strong> means a unique account created for You to
                        access our Service or parts of our Service.
                    </Text>

                    {/* Affiliate */}
                    <Text>
                        <strong>Affiliate</strong> means an entity that controls, is controlled
                        by or is under common control with a party, where "control" means
                        ownership of 50% or more of the shares, equity interest or other
                        securities entitled to vote for election of directors or other managing
                        authority.
                    </Text>

                    {/* Application */}
                    <Text>
                        <strong>Application</strong> refers to Y Drive, the software program
                        provided by the Company.
                    </Text>

                    {/* Company */}
                    <Text>
                        <strong>Company</strong> (referred to as either "the Company", "We",
                        "Us" or "Our" in this Agreement) refers to Y Drive, 105 B.
                    </Text>

                    {/* Country */}
                    <Text>
                        <strong>Country</strong> refers to: Alaska, United States
                    </Text>

                    {/* Device */}
                    <Text>
                        <strong>Device</strong> means any device that can access the Service
                        such as a computer, a cellphone or a digital tablet.
                    </Text>

                    {/* Personal Data */}
                    <Text>
                        <strong>Personal Data</strong> is any information that relates to an
                        identified or identifiable individual.
                    </Text>

                    {/* Service */}
                    <Text>
                        <strong>Service</strong> refers to the Application.
                    </Text>

                    {/* Service Provider */}
                    <Text>
                        <strong>Service Provider</strong> means any natural or legal person who
                        processes the data on behalf of the Company. It refers to third-party
                        companies or individuals employed by the Company to facilitate the
                        Service, to provide the Service on behalf of the Company, to perform
                        services related to the Service or to assist the Company in analyzing
                        how the Service is used.
                    </Text>

                    {/* Usage Data */}
                    <Text>
                        <strong>Usage Data</strong> refers to data collected automatically,
                        either generated by the use of the Service or from the Service
                        infrastructure itself (for example, the duration of a page visit).
                    </Text>

                    {/* You */}
                    <Text>
                        <strong>You</strong> means the individual accessing or using the
                        Service, or the company, or other legal entity on behalf of which such
                        individual is accessing or using the Service, as applicable.
                    </Text>

                    {/* Collecting and Using Your Personal Data */}
                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Collecting and Using Your Personal Data
                    </Heading>

                    {/* Types of Data Collected */}
                    <Heading as="h3" fontSize="md" mt={2} mb={2}>
                        Types of Data Collected
                    </Heading>

                    {/* Personal Data */}
                    <Text>
                        <strong>Personal Data</strong>
                    </Text>
                    <Text>
                        While using Our Service, We may ask You to provide Us with certain
                        personally identifiable information that can be used to contact or
                        identify You. Personally identifiable information may include, but is
                        not limited to:
                    </Text>

                    {/* First name and last name */}
                    <Text>- First name and last name</Text>

                    {/* Usage Data */}
                    <Text>- Usage Data</Text>

                    {/* ... (other items) ... */}

                    {/* Usage Data */}
                    <Heading as="h3" fontSize="md" mt={4} mb={2}>
                        Usage Data
                    </Heading>

                    <Text>
                        <strong>Usage Data</strong> is collected automatically when using the
                        Service.
                    </Text>
                    <Text>
                        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                        When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
                        We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
                    </Text>

                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Use of Your Personal Data
                    </Heading>

                    {/* Purposes */}
                    <Text>
                        The Company may use Personal Data for the following purposes:
                    </Text>
                    <ul>
                        <li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
                        <li>To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
                        {/* Add more list items for other purposes */}
                    </ul>

                    {/* Sharing */}
                    <Text>
                        We may share Your personal information in the following situations:
                    </Text>
                    <ul>
                        <li>With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</li>
                        <li>For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                        {/* Add more list items for other sharing situations */}
                    </ul>

                    {/* Retention of Your Personal Data */}
                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Retention of Your Personal Data
                    </Heading>
                    <Text>
                        The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                    </Text>

                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Transfer of Your Personal Data
                    </Heading>
                    <Text>
                        The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                        The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
                    </Text>

                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Delete Your Personal Data
                    </Heading>
                    <Text>
                        You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.
                        Our Service may give You the ability to delete certain information about You from within the Service.
                        You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.
                        Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.
                    </Text>

                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Disclosure of Your Personal Data
                    </Heading>
                    <Text>
                        If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
                        Law enforcement
                        Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
                        Other legal requirements
                        The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
                        Comply with a legal obligation
                        Protect and defend the rights or property of the Company
                        Prevent or investigate possible wrongdoing in connection with the Service
                        Protect the personal safety of Users of the Service or the public
                        Protect against legal liability
                    </Text>

                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Security of Your Personal Data
                    </Heading>
                    <Text>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</Text>

                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Children's Privacy
                    </Heading>
                    <Text>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
                        If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</Text>

                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Links to Other Websites
                    </Heading>
                    <Text>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
                        We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</Text>

                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Changes to this Privacy Policy
                    </Heading>
                    <Text>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
                        We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</Text>

                    <Heading as="h2" fontSize="lg" mt={4} mb={2}>
                        Contact Us
                    </Heading>
                    <Text>If you have any questions about this Privacy Policy, You can contact us: <chakra.a>[email protected]</chakra.a></Text>

                </Box>
            </Container>
        </>
    )
}