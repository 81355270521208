import PickUpLocationIcon from "../../assets/PickUpLocationIcon.svg";
import DropOffLocationIcon from "../../assets/DropOffLocationIcon.svg";
import StopsLocationSvg from "../../assets/StopsLocationSvg.svg";

export const getStylers = () => {
    return [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#7c93a3"
                },
                {
                    "lightness": "-10"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#a0a4a5"
                }
            ]
        },
        {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#62838e"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#dde3e3"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#3f4a51"
                },
                {
                    "weight": "0.30"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "poi.attraction",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.business",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.government",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.place_of_worship",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.school",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.sports_complex",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-100"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#bbcacf"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "lightness": "0"
                },
                {
                    "color": "#bbcacf"
                },
                {
                    "weight": "0.50"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#a9b4b8"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "invert_lightness": true
                },
                {
                    "saturation": "-7"
                },
                {
                    "lightness": "3"
                },
                {
                    "gamma": "1.80"
                },
                {
                    "weight": "0.01"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#a3c7df"
                }
            ]
        }
    ]
}

export const getIconsForMarkers = (index, totalItem = 3) => {
    let iconName = StopsLocationSvg;
    if (index === 0) {
        iconName = PickUpLocationIcon;
    } else if (index === totalItem) {
        iconName = DropOffLocationIcon;
    }
    return iconName;
}

export const updateZoomOnSingleView = (map, mapZoom, setMapZoom) => {
    if (map) {
        let zoom = map.getZoom();
        if (zoom < 6) {
            setMapZoom(6);
        } else if (zoom > 6 && zoom <= 13) {
            setMapZoom(11);
        } else if (zoom > 13 && zoom <= 15) {
            setMapZoom(12.5);
        }
        map.setZoom(mapZoom);
    }
}

export const getBoundsPrepared = (coordinatesFlatArrayList) => {
    const bounds = new window.google.maps.LatLngBounds();
    coordinatesFlatArrayList.forEach(item => {
        bounds.extend({
            lat: item.location.lat,
            lng: item.location.lng,
        });
    });
    return bounds;
}

export const mapContainerStyle = {
    width: '100vw',
    height: '100vh'
};

export const getMapOptions = () => {
    return {
        controlSize: 24,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: true,
        scaleControl: true,
        rotateControl: true,
        scrollwheel: true,
        disableDoubleClickZoom: true,
        gestureHandling: "greedy",
        clickableIcons: false,
        isFractionalZoomEnabled: true,
        styles: getStylers()
    }
}

export const getPolylineOptions = () => {
    return {
        strokeColor: "#47a8de",
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: "#47a8de",
        fillOpacity: 0.5,
        zIndex: 1,
    }
}